define("discourse/plugins/discourse-follow/discourse/templates/feed", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "ember-route-template", "discourse/components/post-list", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _emberRouteTemplate, _postList, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get viewingSelf() {
      return this.args.model.user.id === this.currentUser.id;
    }
    async loadMore() {
      if (!this.args.model.canLoadMore) {
        return [];
      }
      await this.args.model.findItems();
      return this.args.model.content;
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          {{#if @model.noContent}}
            {{#if this.viewingSelf}}
              <div class="alert alert-info">{{i18n
                  "user.feed.empty_feed_you"
                }}</div>
            {{else}}
              <div class="alert alert-info">
                {{i18n "user.feed.empty_feed_other" username=@model.user.username}}
              </div>
            {{/if}}
          {{else}}
            <PostList
              @urlPath="postUrl"
              @posts={{@model.content}}
              @fetchMorePosts={{this.loadMore}}
              @additionalItemClasses="follow-stream-item"
              class="follow-stream"
            />
          {{/if}}
        
    */
    {
      "id": "4fcdF/tC",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"noContent\"]],[[[41,[30,0,[\"viewingSelf\"]],[[[1,\"          \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[32,0],[\"user.feed.empty_feed_you\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"user.feed.empty_feed_other\"],[[\"username\"],[[30,1,[\"user\",\"username\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[8,[32,1],[[24,0,\"follow-stream\"]],[[\"@urlPath\",\"@posts\",\"@fetchMorePosts\",\"@additionalItemClasses\"],[\"postUrl\",[30,1,[\"content\"]],[30,0,[\"loadMore\"]],\"follow-stream-item\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[\"@model\"],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-follow/discourse/templates/feed.js",
      "scope": () => [_discourseI18n.i18n, _postList.default],
      "isStrictMode": true
    }), this))();
  });
});